import { sequenceT } from "fp-ts/lib/Apply";
import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { Applicative, filter, fromNullable, getOrElse, isNone, map } from "fp-ts/lib/Option";
import Cookie from "js-cookie";

import * as V2Router from "@scripts/generated/routers/v2Router";

import type { QCustomEvent } from "../dom/q";
import { Q } from "../dom/q";
import { html } from "../dom/unsafeHtml";
import { Form } from "../form/form";
import { lockForm } from "../form/formUtil";
import type { Method, UrlInterface } from "../routes/urlInterface";
import { removeUrlParam, urlEq } from "../routes/urlInterface";
import { Alert } from "../ui/alert";
import { eq } from "../util/eq";
import { hasKey } from "../util/hasKey";

const optEq = <A>(o1: Option<A>, o2: Option<A>): boolean => pipe(
  sequenceT(Applicative)(o1, o2),
  map(([_o1, _o2]) => eq(_o1, _o2)),
  getOrElse(() => isNone(o1) && isNone(o2))
);

export class User {
  static readonly institutionalInvestorInfoModalId = "institutionalInvestorInfoModal";
  static readonly jsSessionCookieName = "JS_SESSION";
  static readonly logoutNotificationId = "logout-notification";
  static logoutNotificationTimeout: number;

  static removeNoRedirParam(): void {
    removeUrlParam("noXdRedir");
  }

  static initLogoutNotification(): void {
    User._logoutNotification(fromNullable(Cookie.get(User.jsSessionCookieName)));
    Q.body.listen(Form.beforeSubmitEvent, (e: QCustomEvent) => pipe(
      fromNullable(e.getAttr("detail")),
      filter(hasKey<"url", UrlInterface<Method>>("url")),
      filter((x: { url: UrlInterface<Method> }) =>
        urlEq.equals(x.url, V2Router.baseAuthControllerLoginPost())
        || urlEq.equals(x.url, V2Router.baseAuthControllerSignupPost())
        || urlEq.equals(x.url, V2Router.baseAuthControllerResetForgottenPassword())),
      map(User.stopLogoutNotification)
    ));
    Q.body.listen("click", "#window-location-reload", () => window.location.reload());
  }

  static stopLogoutNotification(): void {
    window.clearTimeout(User.logoutNotificationTimeout);
  }

  static _logoutNotification(initialValue: Option<string>): void {
    const c = fromNullable(Cookie.get(User.jsSessionCookieName));
    if (!optEq(initialValue, c) && isNone(Q.one(`#${User.logoutNotificationId}`))) {
      Alert.show(User.logoutNotificationId, "alert-warning",

        html`You have ${isNone(initialValue) ? "logged in" : isNone(c) ? "logged out" : "switched accounts"} from another tab or window.
          <a id="window-location-reload">Refresh</a> the page to reload your session.`,
        0,
        ["alert-pushdown"]
      );
      Q.all("form").forEach(lockForm);
    }
    User.logoutNotificationTimeout = window.setTimeout(() => User._logoutNotification(c), 500);
  }
}
