import { compact } from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { chain, fold, isSome, map, none } from "fp-ts/lib/Option";

import { Static } from "@scripts/bondlinkStatic";
import { onScroll } from "@scripts/dom/onScroll";
import type { QEvent } from "@scripts/dom/q";
import { Q } from "@scripts/dom/q";
import { invoke0, invoke1 } from "@scripts/util/invoke";

export class MainNav {
  static alerts: Option<Q> = none;
  static nav: Option<Q> = none;
  static navMenu: Option<Q> = none;
  static navList: Option<Q> = none;
  static blBar: Option<Q> = none;
  static stuck: boolean = false;

  static get blBarHeight(): number {
    return [MainNav.alerts, MainNav.blBar].map(fold(() => 0, invoke0("getHeightWithMargin")))
      .reduce((acc: number, a: number) => acc + a);
  }

  static updateStuck(stuck: boolean): void {
    MainNav.stuck = stuck;
  }

  static stick = (nav: Q) => {
    const isScrollable = Q.body.element.scrollHeight > Q.body.element.clientHeight;

    if (!isScrollable) {
      return;
    }

    function _stick(): void {
      window.setTimeout(() => {
        nav
          .removeClass("pre-sticky")
          .addClass("sticky")
          .reflow();
        MainNav.updateStuck(true);
      },
        Static.baseTransitionDelay);
    }

    nav.addClass("pre-sticky");

    if (MainNav.isOpen(nav)) {
      window.setTimeout(() => {
        MainNav.close(nav);
        _stick();
      },
        Static.baseTransitionDelay);
    } else {
      _stick();
    }
  };

  static unstick = (nav: Q) => {
    nav.addClass("pre-sticky");

    window.setTimeout(
      () => {
        nav
          .removeClass(["sticky", "pre-sticky"])
          .reflow();
        MainNav.updateStuck(false);
      },
      Static.baseTransitionDelay);
  };

  static scrollUpdate = (nav: Q, origNavHeight: number) => (scrollTop: number) => {
    if (scrollTop >= origNavHeight && !MainNav.stuck) {
      MainNav.stick(nav);
    } else if (scrollTop < origNavHeight && MainNav.stuck) {
      MainNav.unstick(nav);
    }
  };

  static init(): void {
    const media = window.matchMedia(Static.matchMedia.lg);
    MainNav.alerts = Q.one("#header-alert-top-container");
    MainNav.nav = Q.one(".main-nav:not(.no-sticky)");
    MainNav.navMenu = pipe(MainNav.nav, chain(invoke1("one")(".nav-menu")));
    MainNav.navList = pipe(MainNav.nav, chain(invoke1("one")(".nav-lists-container")));
    MainNav.blBar = pipe(MainNav.nav, chain(invoke1("one")(".bondlink-bar")));

    if (media.matches) {
      MainNav.attachHoverListeners();
    } else {
      MainNav.attachClickListener();
    }
    MainNav.attachFocusListeners();


    media.addListener((mql: MediaQueryListEvent) => {
      if (mql.matches) {
        Q.body.off("click", ".menu-mobile");
        MainNav.attachHoverListeners();
      } else {
        compact([MainNav.navMenu, MainNav.navList]).forEach((menu: Q) => {
          menu.off("mouseenter");
          menu.off("mouseleave");
        });
        MainNav.attachClickListener();
      }
    });

    Q.all(".main-nav.open").forEach(MainNav.toggle(true));

    map((nav: Q) => {
      const origNavHeight = MainNav.blBarHeight;
      onScroll(MainNav.scrollUpdate(nav, origNavHeight));
      MainNav.updateStuck(false);
      MainNav.scrollUpdate(nav, origNavHeight)(Q.getScrollTop());
    })(MainNav.nav);
  }

  static bodyHandler(e: QEvent): void {
    if (fold(() => false, (t: Q) => isSome(t.closest(".nav-menu")))(e.originationElement)) {
      return;
    }

    map(MainNav.toggleNav)(MainNav.nav);
  }

  static toggleNav = (nav: Q): void => {
    MainNav.toggle(!nav.hasClass("open"))(nav);
  };

  static toggle = (open: boolean) => (nav: Q): void => {
    if (open) {
      MainNav.open(nav);
    } else {
      MainNav.close(nav);
    }
  };

  static attachFocusListeners = (): void => {
    Q.all(".nav-link-secondary").forEach((el: Q) => {
      el.listen("focus", () => {
        map((nav: Q) => !MainNav.isOpen(nav) && MainNav.open(nav))(MainNav.nav);
      });
      el.listen("focusout", () => {
        map(MainNav.toggleNav)(MainNav.nav);
      });
    });
  };

  static attachHoverListeners = (): void => {
    if (isSome(MainNav.navList)) {
      compact([MainNav.navMenu, MainNav.navList]).forEach((menu: Q) => {
        menu.listen("mouseenter", () => {
          map(MainNav.open)(menu.closest(".main-nav"));
        });
        menu.listen("mouseleave", () => {
          map(MainNav.close)(menu.closest(".main-nav"));
        });
      });
    }
  };

  static attachClickListener = (): void => {
    Q.body.listen("click", ".menu-mobile", MainNav.bodyHandler);
  };

  static isOpen = (elem: Q): boolean => elem.hasClass("open");

  static open = (elem: Q): void => {
    elem.addClass("open");
  };

  static close = (elem: Q): void => {
    elem.removeClass("open");
  };
}
